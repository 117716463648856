import React, { Component, useCallback } from 'react';

// UI THEME
import { MuiThemeProvider } from '@material-ui/core';
import theme from "./Config/Theme.js";
import 'mapbox-gl/dist/mapbox-gl.css';
// Client Router
import {BrowserRouter, Route, Switch} from "react-router-dom";
// import PrivateRoute from "./Components/Auth/PrivateRoute.js";

import "../node_modules/draft-js/dist/Draft.css";
import 'typeface-roboto';

// Main Components
import Eleven from "./Eleven/Eleven";
import Home from "./Home";

import store from "./Store";
import {Authenticate, ShowLogin} from "./Actions/auth";
import { withStyles, makeStyles,} from '@material-ui/core';
import Login from "./Containers/Forms/Login";

import Box from '@material-ui/core/Box';
// import Team from "./Components/Contact/Team";
import {AddPageView} from "./Clients/GoogleAnalytics"
import { createBrowserHistory } from 'history';


import { isNull } from 'lodash';

const styles = theme => ({
  app: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "100%",
    margin: 0,
    minWidth: "100%"
  }
});

const useStyles = makeStyles(
  theme => ({
    app: {
      backgroundColor: theme.palette.background.default,
      maxWidth: "100%",
      margin: 0,
      minWidth: "100%"
    },
    buttonTest: {
      position: "fixed",
      top: "300px",
      zIndex: "400"
    },
    buttonExit: {
      position: "fixed",
      top: "320px",
      zIndex: "400"
    }
  }),
  { withTheme: true }
);


const history = createBrowserHistory();

history.listen(location => {
  AddPageView(location.pathname); // Record a pageview for the given page
});

const {authenticated, authenticating} = store.getState().Auth;
if(!authenticated && !authenticating){
  store.dispatch(Authenticate());
}

function App() {

  const classes = useStyles();


  return (
    <BrowserRouter history={history} >
      <MuiThemeProvider theme={theme} >
        <Box height="100%" width="100%" maxWidth="100%" className={classes.app} >
            <Switch>
              <Route exact path="/register" component = {TournamentRegister} />
              <Route exact path="/ranking" component = {TournamentRankings} />
			  <Route exact path="/replays" component = {Replays} />
			  <Route exact path="/replaysDev" component = {ReplaysDev} />
              <Route path="/auth/oa" component = {Oculus} />
              <Route path="/eleven" component={Eleven} />
              <Route path="/community" component={Home} />
              <Route path="/" component={Eleven} />
            </Switch>
            <Route path="/" component={Login} />      
        </Box>
      </MuiThemeProvider>                                                                           
    </BrowserRouter>
  );
}

class Oculus extends Component {

  componentDidMount(){

    var token = window.location.hash;

    var cleanedUp = token.substring(1);

    if(!cleanedUp || cleanedUp === ""){

      store.dispatch(ShowLogin());

      this.props.history.push("/")
    }

    var data = Buffer.from(cleanedUp, "base64")

    var creds = null;

    try {  

      creds = JSON.parse(data);  
    
    } catch (e) {  


      store.dispatch(ShowLogin(true));

      this.props.history.push("/")

      console.log('invalid json');  
    
      return;
    }

    var data = {
      provider: "oculus",
      platform_uid: creds['org_scoped_id'],
      platform_name: "OCULUS",
      platform_code: creds.code, 
      platform_type: "WEB"
    }

    store.dispatch(Authenticate(data));

    const oculusRedirect = localStorage.getItem('oculusRedirect');

    if(oculusRedirect !== isNull){

      localStorage.removeItem('oculusRedirect');
    
      this.props.history.push(oculusRedirect)

    }
    else {
      this.props.history.push("/login")
    }
  }

  render(){
    return (
      <h1>AUTHENTICATING...</h1>
    )
  }
}


class TournamentRegister extends Component {

  componentDidMount(){
    window.location.href = `https://forms.gle/yLhrLMf75bhAhWkr7`;
  }



  render(){
    return (
      <h1>REDIRECTING</h1>
    )
  }
}
class TournamentRankings extends Component {

  componentDidMount(){
    window.location.href = `http://lavadesignstudio.co.uk/eleven-rankings/`;
  }



  render(){
    return (
      <h1>LOADING</h1>
    )
  }
}

class ReplaysDev extends Component {

  componentDidMount(){
	const fetchData = async () => {
      try {
        const response = await fetch('https://hub.dev.elevenvr.com/api/userManagement/GetOtherLocalIpsOfActiveUsersOnSamePublicIP');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();
        handleData(data);
      } catch (error) {
        handleError(error);
      }
    };

    // Fetch the data when the component is mounted
    fetchData();
    function handleData(data){
		if(data != null && data.length >5)
		{
			document.querySelector('#foundDeviceLink').innerHTML = "<a href='http://"+data+":8090'>Eleven App Running On Same Network. Click here to browser files (such as replay recordings)</a>";
		}
		else
		{
			document.querySelector('#foundDeviceLink').innerHTML = "no devices found on local network";
		}
	}
	function handleError(error){
		document.querySelector('#foundDeviceLink').innerHTML = "server not ready for this call";
	}
  }

  render(){
    return (
		  <>
			<h1>Found Devices:</h1>
			<br/>
			<div id="foundDeviceLink">FoundDeviceWillGoHere</div>
		  </>
    )
  }
}

class Replays extends Component {

  componentDidMount(){
	const fetchData = async () => {
      try {
        const response = await fetch('https://hub.elevenvr.com/api/userManagement/GetOtherLocalIpsOfActiveUsersOnSamePublicIP');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();
        handleData(data);
      } catch (error) {
        handleError(error);
      }
    };

    // Fetch the data when the component is mounted
    fetchData();
    function handleData(data){
		if(data != null && data.length >5)
		{
			document.querySelector('#foundDeviceLink').innerHTML = "<a href='http://"+data+":8090'>Eleven App Running On Same Network. Click here to browser files (such as replay recordings)</a>";
		}
		else
		{
			document.querySelector('#foundDeviceLink').innerHTML = "no devices found on local network";
		}
	}
	function handleError(error){
		document.querySelector('#foundDeviceLink').innerHTML = "server not ready for this call";
	}
  }

  render(){
    return (
		  <>
			<h1>Found Devices:</h1>
			<br/>
			<div id="foundDeviceLink">FoundDeviceWillGoHere</div>
		  </>
    )
  }
}

export default withStyles(styles)(App);